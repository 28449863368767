import React from 'react'

import moment from 'moment'

import formatTimezoneOffset from 'utils/format-timezone'

import type Partner from 'redesign/types/Partner'
import type Timezone from 'redesign/types/Timezone'

import { containerStyles, titleStyles, divider } from './PartnerInfo.module.css'

type Props = {
  partner: Partner
  timezones: Timezone[]
}

const InfoItem = ({ label, value }: { label: string; value: string }) => (
  <div>
    <div className={titleStyles}>{label}</div>
    {value}
  </div>
)

const Divider = () => <div className={divider} />

export const PartnerInfo = ({ partner, timezones }: Props) => {
  const {
    dateOfIncorporation,
    workingHoursFrom,
    workingHoursTo,
    baseTimezone
  } = partner
  const partnerSince = dateOfIncorporation
    ? moment(dateOfIncorporation).format('DD/MM/YYYY')
    : 'N/A'

  const from = moment.utc(workingHoursFrom, 'HH:mm:ss.SSS').format('HH:mm')
  const to = moment.utc(workingHoursTo, 'HH:mm:ss.SSS').format('HH:mm')
  const workingHours = from && to ? `From ${from} to ${to}` : 'N/A'

  // Get the timezone name from baseTimezone
  const [timezoneName] = baseTimezone?.split(/ (?=[^ ]+$)/) || [undefined]
  const timezone = timezoneName
    ? timezones.find(({ name }) => timezoneName === name)
    : undefined
  const tz = timezone ? `${formatTimezoneOffset(timezone.offset)} (UTC)` : 'N/A'

  return (
    <div className={containerStyles}>
      <InfoItem label={`Partner Since`} value={partnerSince} />
      <Divider />
      <InfoItem label={`Standard Working Hours`} value={workingHours} />
      <Divider />
      <InfoItem label={`Timezone`} value={tz} />
    </div>
  )
}
