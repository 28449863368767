import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getPartnerStatistics } from 'services/partners'

import type { IUsePartnersReturn, UsePartnerParams } from './usePartnerStatistics.types'

const usePartnerStatistics = (params: UsePartnerParams): IUsePartnersReturn => {
  const { partnerId } = params

  const { data: partnersStatisticsData, isLoading } = useQuery({
    queryKey: ['partnerStatistics', params],
    queryFn: () => getPartnerStatistics(partnerId)
  })
  const data = useMemo(
    () => partnersStatisticsData?.data || {},
    [partnersStatisticsData]
  )

  return {
    data,
    isLoading
  }
}

export default usePartnerStatistics
