import { useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { UploadProgressEvent } from 'rc-upload/lib/interface'

import { message } from 'components/Message'

import {
  uploadFile as uploadFileService,
  deleteFile as deleteFileService
} from 'services/upload'

import type { File } from 'redesign/types/File'

import type { UseFileUploadParams, UseFileUploadReturn } from './useFileUpload.types'

export const useFileUpload = ({
  collectionName
}: {
  collectionName: string
}): UseFileUploadReturn => {
  const queryClient = useQueryClient()

  const [fileData, setFileData] = useState(null)
  const [fileList, setFileList] = useState<File[]>([])

  const [uploadLoading, setUploadLoading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  const uploadFile = async (params: UseFileUploadParams) => {
    const formData = new FormData()

    const { collectionId, fileId, attributeName, files, onProgress } = params

    const dataObject = {
      ref: collectionName,
      path: attributeName,
      field: attributeName,
      refId: collectionId,
      files
    }

    Object.keys(dataObject).forEach(key => {
      const value = dataObject[key as keyof typeof dataObject]
      formData.append(key, value as string | Blob)
    })

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event: Required<UploadProgressEvent>) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        setUploadProgress(percent)
        onProgress && onProgress({ percent })
      }
    }

    setUploadLoading(true)

    const {
      data: [fileData]
    } = await uploadFileService(formData, config)
    queryClient.invalidateQueries({ queryKey: [`${collectionName}s`] })

    setFileData(fileData)
    setFileList([
      {
        status: 'done',
        uid: fileId,
        url: fileData.url,
        name: fileData.name
      }
    ])

    setUploadLoading(false)
    setUploadProgress(0)
  }

  const clearFileList = () => {
    setFileList([])
  }

  // Delete
  const deleteMutation = useMutation({
    mutationFn: deleteFileService,
    onSuccess: () => {
      message.success('File deleted')
      queryClient.invalidateQueries({ queryKey: [`${collectionName}s`] })
    },
    onError: () => {
      message.error('Delete Note failed')
    }
  })

  const deleteFile = (fileId: number) => {
    deleteMutation.mutate(fileId)
  }

  const isLoading = uploadLoading

  return {
    uploadFile,
    fileList,
    clearFileList,
    fileData,
    uploadProgress,
    deleteFile,
    isLoading
  }
}
