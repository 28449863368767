import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { listPartnerRolesConfig } from 'services/partnerRoles'

import type { IUsePartnerRoleConfigReturn } from './usePartnerRolesConfig.types'

const usePartnerRolesConfig = (): IUsePartnerRoleConfigReturn => {
  const { data: partnerRolesConfigData, isLoading } = useQuery({
    queryKey: ['partnerRolesConfig'],
    queryFn: () => listPartnerRolesConfig()
  })
  const data = useMemo(() => partnerRolesConfigData?.data ?? [], [partnerRolesConfigData])

  return {
    data,
    isLoading
  }
}
export default usePartnerRolesConfig
