import React, { useState } from 'react'

import { ContactModal } from './components/Contact/ContactModal'
import { Contacts } from './components/Contacts/Contacts'

import useCreatePartnerTeam from '../../hooks/useCreatePartnerTeam/useCreatePartnerTeam'
import usePartnerContacts from '../../hooks/usePartnerContacts/usePartnerContacts'
import usePartnerTeam from '../../hooks/usePartnerTeam/usePartnerTeam'

import type Partner from 'redesign/types/Partner'
import type { PartnerContact } from 'redesign/types/PartnerContact'
import type { PartnerTeam } from 'redesign/types/PartnerTeam'

type Props = {
  partner: Partner
}

export const PartnerContactsContainer = ({ partner }: Props) => {
  const {
    data: partnerContactsData,
    createPartnerContacts,
    updatePartnerContacts,
    deletePartnerContacts
  } = usePartnerContacts({ partnerId: partner.id })
  const { data: partnerTeamsData } = usePartnerTeam({ partner: partner?.id })
  const { createPartnerTeam } = useCreatePartnerTeam()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [contact, setContact] = useState<PartnerContact>()

  const onClickSave = async (contact: PartnerContact) => {
    const enrichedContact = {
      ...contact,
      partner: partner.id,
      team: (contact?.team as PartnerTeam)?.id
    }
    if (!contact.id) {
      await createPartnerContacts(enrichedContact, {
        onSuccess: () => setIsModalOpen(false)
      })
    } else {
      await updatePartnerContacts(
        {
          contactId: contact.id,
          contact: enrichedContact
        },
        { onSuccess: () => setIsModalOpen(false) }
      )
    }
  }

  const onClickDelete = (id: number) => {
    deletePartnerContacts(id)
  }

  const onClickCancel = () => {
    setIsModalOpen(false)
    setContact(undefined)
  }

  return (
    <>
      <Contacts
        partnerContactsData={partnerContactsData}
        setIsModalOpen={setIsModalOpen}
        setContact={setContact}
        onClickDelete={onClickDelete}
      />
      {isModalOpen && (
        <ContactModal
          visible={isModalOpen}
          contact={contact}
          partnerTeamsData={partnerTeamsData}
          onClickSave={onClickSave}
          onClickCancel={onClickCancel}
          addPartnerTeam={createPartnerTeam}
        />
      )}
    </>
  )
}
