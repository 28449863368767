import React from 'react'

import { EditOutlined } from '@ant-design/icons'
import clsx from 'clsx'

import { Col } from 'components/Col'
import { Row } from 'components/Row'
import { Tag } from 'components/Tag'

import type Partner from 'redesign/types/Partner'

import {
  editIconStyles,
  titleContainerStyles,
  titleStyles,
  skillStyles,
  preserveLineBreaksStyles
} from './Overview.module.css'

type Props = {
  partnerData: Partner
  onEdit: () => void
}

const Overview = (props: Props) => {
  const { partnerData, onEdit } = props

  const {
    cultureSummary,
    description,
    skills = [],
    numberOfInterviews,
    requiresCodingTest,
    industries = []
  } = partnerData

  return (
    <>
      <Row className={preserveLineBreaksStyles}>
        <Col span={23}>
          <div className={titleStyles}>Culture Summary</div>
        </Col>
        <Col span={1}>
          <EditOutlined className={editIconStyles} onClick={onEdit} />
        </Col>
        <Col>{cultureSummary}</Col>
      </Row>
      <Row className={clsx(titleContainerStyles, preserveLineBreaksStyles)}>
        <Col span={24}>
          <div className={titleStyles}>Description</div>
        </Col>
        <Col>{description}</Col>
      </Row>
      <Row className={titleContainerStyles}>
        <Col span={24}>
          <div className={titleStyles}>Tech Stack</div>
        </Col>
        <Col>
          {skills.map(({ id, title }) => (
            <Tag className={skillStyles} key={id} color="blue">
              {title}
            </Tag>
          ))}
        </Col>
      </Row>
      <Row className={titleContainerStyles}>
        <Col span={24}>
          <div className={titleStyles}>Industries</div>
        </Col>
        <Col>
          {industries.map(({ id, description }) => (
            <Tag className={skillStyles} key={id} color="blue">
              {description}
            </Tag>
          ))}
        </Col>
      </Row>
      <Row className={clsx(titleContainerStyles, preserveLineBreaksStyles)}>
        <Col span={24}>
          <div className={titleStyles}>Partner Interview Process</div>
        </Col>
        <Col span={24}>{numberOfInterviews}</Col>
      </Row>
      <Row className={titleContainerStyles}>
        <Col span={24}>
          <div className={titleStyles}>Requires live coding test?</div>
        </Col>
        <Col span={24}>{requiresCodingTest ? 'Yes' : 'No'}</Col>
      </Row>
    </>
  )
}

export default Overview
