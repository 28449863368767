import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { useForm } from '../../../../../../../hooks/useForm'

import { IndustriesField } from 'redesign/containers/FormFields/IndustriesField'

import { Button } from 'components/Button'
import { Col } from 'components/Col'
import { Form, FormItem } from 'components/Form'
import { Input } from 'components/Input'
import { Row } from 'components/Row'

import { App } from 'redesign/components/App'

import { websiteRegex } from 'redesign/utils/constants'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Partner from 'redesign/types/Partner'

type PartnerFormProps = {
  closeForm: () => void
  createPartner: (values: Partner, callbacks?: MutationOptions) => void
  isLoading: boolean
}

const PartnerForm = ({
  closeForm,
  createPartner,
  isLoading
}: PartnerFormProps) => {
  const [form] = useForm()
  const { modal: { confirm } } = App.useApp()

  const onFinish = () => {
    const values = form.getFieldsValue()

    createPartner(values, { onSuccess: closeForm })
  }

  const onClickCancel = () => {
    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeForm()
      }
    })
  }

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Row
        justify="end"
        style={{
          marginBottom: '20px'
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{
            marginRight: '10px'
          }}
        >
          Confirm
        </Button>
        <Button onClick={onClickCancel}>Cancel</Button>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <FormItem
            name="name"
            label="Partner Name:"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name of the Partner" />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem
            name="description"
            label="Description:"
            rules={[{ required: true }]}
          >
            <Input.TextArea
              autoSize={{ maxRows: 4 }}
              placeholder="Description of the company"
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <FormItem
            name="website"
            label="Website:"
            rules={[
              {
                validator: (_, value, callback) => {
                  const errorMessage = 'Please enter a valid website'
                  if (!value || websiteRegex.test(value)) {
                    callback()
                  } else {
                    callback(errorMessage)
                  }
                }
              }
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 10 }} />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem
            name="industries"
            label="Industries:"
            rules={[{ required: false }]}
          >
            <IndustriesField mode="multiple" />
          </FormItem>
        </Col>
      </Row>
    </Form>
  )
}

export default PartnerForm
