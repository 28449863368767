import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import { createPartnerTeam as createPartnerTeamService } from 'services/partnerTeams'

import type { MutationOptions } from 'redesign/types/Mutation'
import type { PartnerTeam } from 'redesign/types/PartnerTeam'

import type { IUsePartnerTeamReturn } from './useCreatePartnerTeam.types'

const useCreatePartnerTeam = (): IUsePartnerTeamReturn => {
  const queryClient = useQueryClient()

  // Post
  const createMutation = useMutation<AxiosResponse, AxiosError, PartnerTeam>({
    mutationFn: team => createPartnerTeamService(team),
    onSuccess: () => {
      message.success('Partner Team created')
      queryClient.invalidateQueries({ queryKey: ['partners-teams'] })
    },
    onError: () => {
      message.error('Create Partner Team failed')
    }
  })

  const createPartnerTeam = async (team: PartnerTeam, callbacks?: MutationOptions) => {
    await createMutation.mutateAsync(team, callbacks)
  }

  return {
    createPartnerTeam,
  }
}

export default useCreatePartnerTeam
