import { useMemo } from 'react'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import {
  readPartner,
  updatePartner as updatePartnerService
} from 'services/partners'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Partner from 'redesign/types/Partner'

import type { IUsePartnersReturn, UsePartnerParams } from './usePartner.types'

const usePartner = (params: UsePartnerParams): IUsePartnersReturn => {
  const queryClient = useQueryClient()

  const { partnerId, ...rest } = params
  const {
    data: partnersData,
    isLoading,
    error
  } = useQuery<AxiosResponse<Partner>, AxiosError>({
    queryKey: ['partners', params],
    queryFn: () =>
      readPartner(partnerId, {
        params: {
          populate: ['industries', 'roleSkills', 'logo', 'banner', 'skills'],
          ...rest
        }
      }),
    retry: false
  })
  const data = useMemo(() => partnersData?.data, [partnersData?.data])

  const updateMutation = useMutation<AxiosResponse, AxiosError, Partner>({
    mutationFn: formValues => updatePartnerService(partnerId, formValues),
    onSuccess: () => {
      message.success('Partner updated')
      queryClient.invalidateQueries({ queryKey: ['partner'] })
      queryClient.invalidateQueries({ queryKey: ['partners'] })
    },
    onError: () => message.error('Update Partner failed')
  })

  const updatePartner = (values: Partner, callbacks?: MutationOptions) => {
    updateMutation.mutate(values, callbacks)
  }

  return {
    data,
    isLoading,
    updatePartner,
    updatePartnerIsLoading: updateMutation.isLoading,
    error
  }
}

export default usePartner
