import React from 'react'

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'

import AvatarFallback from 'assets/avatar-fallback.png'

import { Button } from 'components/Button'
import { message } from 'components/Message'
import type {
  UploadRequestOption,
  RcFile,
  UploadFile,
  DraggerProps,
  UploadProgressEvent
} from 'components/Upload'
import { Dragger } from 'components/Upload'

import type { UseFileUploadReturn } from 'redesign/hooks/useFileUpload/useFileUpload.types'

import { allowedImageFormats } from 'redesign/utils/constants'

import type Partner from 'redesign/types/Partner'

import {
  logoStyles,
  logoGradientStyles,
  actionButtonsStyles,
  deleteStyles
} from './PartnerLogo.module.css'

type PartnerLogoProps = {
  partner: Partner
  fileUpload: UseFileUploadReturn
}

export const PartnerLogo = ({ partner, fileUpload }: PartnerLogoProps) => {
  const { uploadFile, fileList, deleteFile } = fileUpload

  const onChange: DraggerProps['onChange'] = info => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  const beforeUpload = (file: RcFile) => {
    const extension = file.name.split('.').pop()
    if (extension && !allowedImageFormats.includes(extension)) {
      message.error(`${file.name} file extension is not allowed.`)
      return false
    }
    return true
  }

  const uploadLogo = async (options: UploadRequestOption<File>) => {
    const { file, onProgress } = options
    await uploadFile({
      collectionId: partner.id,
      fileId: (file as RcFile).uid,
      attributeName: 'logo',
      files: file as RcFile,
      onProgress: onProgress as (event: UploadProgressEvent) => void
    })
  }

  const deleteLogo = (file?: UploadFile) => {
    // @TODO(TS migration)
    // @ts-expect-error 'id' prop does not exist on any of existing **File types.
    // Where does it coming from?. Is it a bug?
    const fileId = file.uid ?? file.id
    deleteFile(Number(fileId))
  }

  const partnerLogo = partner?.logo?.url

  const draggerProps: DraggerProps = {
    accept: allowedImageFormats.join(','),
    onChange,
    beforeUpload,
    onRemove: deleteLogo,
    customRequest: options => uploadLogo(options),
    fileList: (fileList as unknown) as Array<UploadFile<File>>,
    multiple: false,
    directory: false,
    showUploadList: false,
    style: {
      backgroundImage: `
      url(${partnerLogo ?? AvatarFallback})
      `,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      border: '3px solid white',
      borderRadius: '100px'
    },
    className: logoStyles
  }

  return (
    <Dragger {...draggerProps}>
      <div className={logoGradientStyles}>
        <Button
          size="large"
          type="text"
          className={actionButtonsStyles}
          icon={<UploadOutlined />}
        />
        {partnerLogo && (
          <Button
            size="large"
            type="text"
            className={actionButtonsStyles}
            icon={<DeleteOutlined className={deleteStyles} />}
            onClick={event => {
              event.stopPropagation()
              deleteLogo(partner.logo)
            }}
          />
        )}
      </div>
    </Dragger>
  )
}
