import React from 'react'

import { useLocation } from '@reach/router'

import { Skeleton } from 'redesign/components'

import { PartnerDetailTabs } from './components/DetailTabs/PartnerDetailTabs'
import { PartnerMainSection } from './components/MainSection/PartnerMainSection'

import { Result } from 'components/Result'

import usePartner from '../../hooks/usePartner/usePartner'

import { useFileUpload } from 'redesign/hooks/useFileUpload'
import { usePartnerRolesConfig } from 'redesign/hooks/usePartnerRolesConfig'

import { getNotFoundPageErrorMessage } from 'redesign/utils/helpers'

import { container } from './PartnerDetailContainer.module.css'

type Props = {
  partnerId?: number
}

export const PartnerDetailContainer = ({ partnerId }: Props) => {
  const location = useLocation()
  const {
    data: partnerData,
    isLoading,
    updatePartner,
    error
  } = usePartner({ partnerId })
  const { data: partnerRolesConfig, isLoading: partnerRolesConfigIsLoading } =
    usePartnerRolesConfig()

  const fileUpload = useFileUpload({ collectionName: 'partner' })

  if (isLoading || partnerRolesConfigIsLoading) {
    return <Skeleton active />
  }

  const errorSubtitleMessage = getNotFoundPageErrorMessage({
    status: error?.response?.status,
    type: 'partner',
    id: partnerId,
    params: location.search
  })

  if (error || !partnerData) {
    return (
      <Result
        status={error?.response?.status}
        title={error?.response?.statusText}
        subTitle={errorSubtitleMessage}
      />
    )
  }

  return (
    <div className={container}>
      <PartnerMainSection
        partner={partnerData}
        fileUpload={fileUpload}
        timezones={partnerRolesConfig.rolesTimezones}
        updatePartner={updatePartner}
      />
      <PartnerDetailTabs partnerData={partnerData} />
    </div>
  )
}
