import React from 'react'

import { Link } from 'gatsby'
import moment from 'moment'

import { useSearchFilter } from 'components/SearchFilter'
import type { ColumnsType } from 'components/Table'
import { Table } from 'components/Table'
import { Tag } from 'components/Tag'

import { compareStrings, sortByField } from 'utils/helpers'

import type Partner from 'redesign/types/Partner'

type Props = {
  isLoading: boolean
  partners: Partner[]
}

export const PartnersTable = (props: Props) => {
  const { isLoading, partners } = props
  const { createSearchableColumn } = useSearchFilter()

  const columns: ColumnsType<Partner> = [
    createSearchableColumn({
      title: 'Name',
      key: 'name',
      sorter: (a: Partner, b: Partner) => compareStrings(a.name, b.name),
      placeholder: 'Search Partner Name',
      onFilter: (value: string, record: Partner) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
      // @TODO(TS migration)
      // @ts-expect-error understand what `Text` represents and type accordingly
      // to help with its props down below
      render: (value: Partner, Text) => (
        <span>
          <Link to={`/partners?id=${value.id}`}>
            <Text text={value.name} />
          </Link>
        </span>
      )
    }),
    {
      title: 'Website',
      dataIndex: 'website'
    },
    {
      title: 'Industries',
      key: 'industries',
      sorter: false,
      render: (value: Partner) =>
        (value.industries || [])
          .sort(sortByField('description'))
          .map(({ id, description }) => (
            <Tag key={`tag-${id}`} color="blue">
              {description}
            </Tag>
          ))
    },
    {
      title: 'Open Roles',
      dataIndex: 'activeRoles'
    },
    {
      title: 'Current Bookings',
      dataIndex: 'activeBookings',
      key: 'activeBookings'
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: value => moment(value).format('YYYY-MM-DD HH:mm'),
      sorter: (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
    }
  ]

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={partners}
        loading={isLoading}
      />
    </div>
  )
}
