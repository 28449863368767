import { useMemo } from 'react'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import {
  listPartnerContactsService,
  createPartnerContactsService,
  updatePartnerContactsService,
  deletePartnerContactsService
} from 'services/partnerContacts'

import type { MutationOptions } from 'redesign/types/Mutation'
import type { PartnerContact } from 'redesign/types/PartnerContact'

import type {
  IUsePartnerContactsReturn,
  PartnerContactParameters,
  UsePartnerContactsParams
} from './usePartnerContacts.types'

const usePartnerContacts = (
  params: UsePartnerContactsParams
): IUsePartnerContactsReturn => {
  const queryClient = useQueryClient()

  const { partnerId } = params
  // Get
  const { data: partnerContactsData, isLoading } = useQuery<
    unknown,
    AxiosError,
    AxiosResponse<PartnerContact[]>
  >({
    queryKey: ['partners-contacts', partnerId],
    queryFn: () =>
      listPartnerContactsService({
        params: {
          partner: partnerId ?? 0,
          populate: ['partner', 'team']
        }
      })
  })

  // Post
  const createMutation = useMutation<AxiosResponse, AxiosError, PartnerContact>(
    {
      mutationFn: contact => createPartnerContactsService(contact),
      onSuccess: () => {
        message.success('PartnerContacts created')
        queryClient.invalidateQueries({ queryKey: ['partners-contacts'] })
      },
      onError: () => {
        message.error('Create PartnerContacts failed')
      }
    }
  )

  const createPartnerContacts = async (
    contact: PartnerContact,
    callbacks?: MutationOptions
  ) => {
    await createMutation.mutateAsync(contact, callbacks)
  }

  // Put
  const updateMutation = useMutation<
    AxiosResponse,
    AxiosError,
    PartnerContactParameters
  >({
    mutationFn: ({ contactId, contact }) =>
      updatePartnerContactsService(contactId, contact),
    onSuccess: () => {
      message.success('PartnerContacts updated')
      queryClient.invalidateQueries({ queryKey: ['partners-contacts'] })
    },
    onError: () => {
      message.error('Update PartnerContacts failed')
    }
  })

  const updatePartnerContacts = async (
    params: PartnerContactParameters,
    callbacks?: MutationOptions
  ) => {
    await updateMutation.mutateAsync(params, callbacks)
  }

  // Delete
  const deleteMutation = useMutation<AxiosResponse, AxiosError, number>({
    mutationFn: contactId => deletePartnerContactsService(contactId),
    onSuccess: () => {
      message.success('PartnerContacts deleted')
      queryClient.invalidateQueries({ queryKey: ['partners-contacts'] })
    },
    onError: () => {
      message.error('Delete PartnerContacts failed')
    }
  })

  const deletePartnerContacts = (contactId: number) => {
    deleteMutation.mutate(contactId)
  }

  const data = useMemo(
    () => partnerContactsData?.data || [],
    [partnerContactsData]
  )
  const dataCount = useMemo(
    () => partnerContactsData?.data.length || 0,
    [partnerContactsData]
  )

  return {
    data,
    dataCount,
    isLoading,
    createPartnerContacts,
    updatePartnerContacts,
    deletePartnerContacts
  }
}

export default usePartnerContacts
