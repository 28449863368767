import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listPartnerContactsService = async params => {
  try {
    const response = await api.get(`/partner-contacts`, params)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const createPartnerContactsService = async partnerContact => {
  try {
    const response = await api.post(`/partner-contacts`, partnerContact)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const updatePartnerContactsService = async (
  partnerContactId,
  params
) => {
  try {
    const response = await api.put(
      `/partner-contacts/${partnerContactId}`,
      params
    )
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const deletePartnerContactsService = async partnerContactId => {
  try {
    const response = await api.delete(`/partner-contacts/${partnerContactId}`)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}
