import React from 'react'

import type { ColumnsType, TablePaginationConfig } from 'components/Table'
import { Table } from 'components/Table'

import type RoleCandidate from 'redesign/types/RoleCandidate'

type Props = {
  profiles: RoleCandidate[]
  columns: ColumnsType<RoleCandidate>
}

const paginationConfig: TablePaginationConfig = {
  position: ['topRight'],
  pageSize: 10
}

const ProfilesTable = ({ profiles, columns }: Props) => (
  <Table
    rowKey="id"
    dataSource={profiles}
    columns={columns}
    pagination={paginationConfig}
    scroll={{ x: true }}
    loading={false}
  />
)

export default ProfilesTable
