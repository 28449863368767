import React, { useState } from 'react'

import { PartnerRolesTable } from './components/PartnerRolesTable/PartnerRolesTable'

import type { RadioChangeEvent } from 'components/RadioGroup'
import { RadioGroup } from 'components/RadioGroup'

import type { HandleAssigneeChangeType } from 'redesign/modules/Role/page/Role.types'

import { usePartnerRoles } from 'redesign/hooks/usePartnerRoles'
import { usePartnerRolesConfig } from 'redesign/hooks/usePartnerRolesConfig'

import type Partner from 'redesign/types/Partner'

import { containerStyles } from './PartnerRolesContainer.module.css'

type Props = {
  partner: Partner
}

const statuses = {
  open: 'Open',
  closed: 'Closed'
}

export const PartnerRoles = ({ partner }: Props) => {
  const [filter, setFilter] = useState(statuses.open)

  const { data, isLoading, addRoleAssigneesMutation, removeRoleAssigneesMutation } = usePartnerRoles({
    params: {
      active: filter === statuses.open,
      partner: partner.id
    }
  })

  const { data: partnerRolesConfig } = usePartnerRolesConfig()

  const handleAssigneeChange =
    (roleId: number) =>
      ({ userId, isAssigned }: HandleAssigneeChangeType) => {
        const functionToCall = isAssigned
          ? removeRoleAssigneesMutation
          : addRoleAssigneesMutation
        functionToCall({ roleId, assignees: [userId] })
      }

  return (
    <div className={containerStyles}>
      <RadioGroup
        options={[statuses.open, statuses.closed]}
        optionType="button"
        buttonStyle="solid"
        value={filter}
        onChange={({ target: { value } }: RadioChangeEvent) => setFilter(value)}
      />
      <PartnerRolesTable
        roles={data}
        isLoading={isLoading}
        partnerRolesConfig={partnerRolesConfig}
        handleAssigneeChange={handleAssigneeChange}
      />
    </div>
  )
}
