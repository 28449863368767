import React from 'react'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

import { ProfileLocation } from 'redesign/components'

import { Button } from 'components/Button'

import type { PartnerContact } from 'redesign/types/PartnerContact'
import type { PartnerTeam } from 'redesign/types/PartnerTeam'

import { cardStyles, cardTitleStyles, cardDataGroupStyles, cardInfoStyles, cardButtonsStyles } from './Contact.module.css'

type Props = {
  contact: PartnerContact
  onDelete: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onClickEdit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const Contact = ({ contact, onDelete, onClickEdit }: Props) => (
  <div className={cardStyles}>
    <div className={cardDataGroupStyles}>
      <div className={cardTitleStyles}>{contact.name}</div>
      <div className={cardInfoStyles}>Role: {contact.role}</div>
      <div className={cardInfoStyles}>Team: {(contact.team as PartnerTeam)?.name}</div>
      <div className={cardInfoStyles}>Location: {
        (contact?.country && contact.timezoneOffset) && (
          <ProfileLocation
            country={{ name: contact?.country }}
            timezoneOffset={contact.timezoneOffset}
            showLabel={false}
          />
        )}
      </div>
      <div className={cardInfoStyles}>Email: {contact.email}</div>
      <div className={cardInfoStyles}>Phone: {contact.phone}</div>
    </div>
    <div className={cardButtonsStyles}>
      <Button
        ghost
        icon={<EditOutlined />}
        onClick={onClickEdit}
        data-testid={`contacts-button-edit`}
      />

      <Button
        type="primary"
        danger
        icon={<DeleteOutlined />}
        data-testid={`contacts-button-delete`}
        onClick={onDelete}
      />
    </div>
  </div>
)
