import React, { useState, useEffect } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useLocation } from '@reach/router'

import { useForm } from '../../../../../../../hooks/useForm'

import { Button } from 'components/Button'
import { Form, FormItem } from 'components/Form'
import { Input } from 'components/Input'
import { Row } from 'components/Row'
import { Select, SelectOption } from 'components/Select'

import { getLocationFieldValue } from 'utils/format-timezone'
import { prepareLocationSelectValue } from 'utils/timezone-column'

import { getLocations } from 'redesign/utils/timezoneUtils'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Partner from 'redesign/types/Partner'
import type { PartnerContact } from 'redesign/types/PartnerContact'
import type { PartnerTeam } from 'redesign/types/PartnerTeam'

import {
  rowStyles,
  labelRowStyles,
  requiredStyles,
  formItemStyles,
  footerStyles
} from './ContactForm.module.css'

type Props = {
  contact?: PartnerContact
  teams: PartnerTeam[]
  onClickSave: (contact: PartnerContact) => void
  onClickCancel: () => void
  addPartnerTeam: (team: PartnerTeam, callbacks?: MutationOptions) => Promise<void>
}

export const ContactForm = ({
  contact,
  teams,
  onClickSave,
  onClickCancel,
  addPartnerTeam
}: Props) => {
  const [form] = useForm()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const id = queryParams.get('id')

  const [partnerTeams, setPartnerTeams] =
    useState<{ label: string; value: number }[]>()
  const [team, setTeam] = useState('')

  const handleSave = async () => {
    try {
      await form.validateFields()
    } catch (e) {
      return
    }
    const values = form.getFieldsValue()

    const teamToSave = teams.find(team => team.id === values.team)
    let contactToSave: PartnerContact = {
      id: values.id ?? contact?.id,
      name: values.name,
      role: values.role,
      email: values.email,
      phone: values.phone,
      partner: values.partner ?? contact?.partner,
      team: teamToSave
    }

    if (values.location) {
      const { country, timezone: timezoneOffset } = prepareLocationSelectValue(values.location)
      contactToSave = {
        ...contactToSave,
        country,
        timezoneOffset,
      }
    }
    onClickSave(contactToSave)
  }

  useEffect(() => {
    if (Array.isArray(teams)) {
      setPartnerTeams(
        teams.map(({ name, id }) => ({
          label: name,
          value: id ?? 0
        }))
      )
    }
  }, [teams])

  const getLocationSelectOptions = () =>
    getLocations().map(({ label, value }) => (
      <SelectOption key={value} value={value.toString()}>
        {label}
      </SelectOption>
    ))

  const createPartnerTeam = async () => {
    if (!team) {
      form.setFields([
        {
          name: 'team',
          errors: ['Please enter team name']
        }
      ])
      return
    }
    await addPartnerTeam({ name: team, partner: id as unknown as Partner }, {})
  }

  return (
    <div>
      <Form
        data-testid="profileMarketingForm-form"
        form={form}
        initialValues={{
          ...contact,
          team: (contact?.team as PartnerTeam)?.id,
          location: getLocationFieldValue(contact?.country, contact?.timezoneOffset),
        }}
      >
        <Row justify="space-between" className={rowStyles}>
          <Row align="middle" className={labelRowStyles}>
            <span className={requiredStyles}>*</span>
            <span>Name:</span>
          </Row>

          <FormItem
            className={formItemStyles}
            name="name"
            rules={[{ required: true, message: 'Name is required' }]}
            data-testid="contactForm-name"
          >
            <Input aria-label="name-input" />
          </FormItem>
        </Row>

        <Row justify="space-between" className={rowStyles}>
          <Row align="middle" className={labelRowStyles}>
            <span>Role:</span>
          </Row>

          <FormItem
            className={formItemStyles}
            name="role"
            data-testid="contactForm-role"
          >
            <Input aria-label="role-input" />
          </FormItem>
        </Row>

        <Row justify="space-between" className={rowStyles}>
          <Row align="middle" className={labelRowStyles}>
            <span>Team:</span>
          </Row>

          <FormItem
            className={formItemStyles}
            name="team"
            data-testid="contactForm-team"
          >
            <Select
              aria-label="team-input"
              showSearch
              optionFilterProp="label"
              onSearch={setTeam}
              options={partnerTeams}
              notFoundContent={
                <Button onClick={createPartnerTeam} type="link">
                  <PlusOutlined /> Add New Partner Team
                </Button>
              }
            />
          </FormItem>
        </Row>

        <Row justify="space-between" className={rowStyles}>
          <Row align="middle" className={labelRowStyles}>
            <span className={requiredStyles}>*</span>
            <span>Email:</span>
          </Row>

          <FormItem
            className={formItemStyles}
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'A valid email is required'
              }
            ]}
            data-testid="contactForm-email"
          >
            <Input aria-label="email-input" />
          </FormItem>
        </Row>

        <Row justify="space-between" className={rowStyles}>
          <Row align="middle" className={labelRowStyles}>
            <span>Phone:</span>
          </Row>

          <FormItem
            className={formItemStyles}
            name="phone"
            rules={[
              {
                required: false,
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[\s./0-9][-\s./0-9]*$/,
                message: 'Phone number must be valid'
              }
            ]}
            data-testid="contactForm-phone"
          >
            <Input aria-label="phone-input" />
          </FormItem>
        </Row>

        <Row justify="space-between" className={rowStyles}>
          <Row align="middle" className={labelRowStyles}>
            <span>Location:</span>
          </Row>

          <FormItem name="location" className={formItemStyles}>
            <Select showSearch>{getLocationSelectOptions()}</Select>
          </FormItem>
        </Row>
      </Form>

      <div className={footerStyles}>
        <Button onClick={onClickCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSave}>
          {contact?.id ? 'Save' : 'Create'}
        </Button>
      </div>
    </div>
  )
}
