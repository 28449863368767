import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import { message } from 'components/Message'

import { createPartner as createPartnerService } from 'services/partners'

import { capitalizeFirstLetter } from 'redesign/utils/stringUtils'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Partner from 'redesign/types/Partner'

import type {
  IUsePartnersReturn,
} from './useCreatePartner.types'

const useCreatePartner = (): IUsePartnersReturn => {
  const queryClient = useQueryClient()
  // Post
  const createMutation = useMutation<AxiosResponse, AxiosError, Partner>({
    mutationFn: partner => createPartnerService(partner),
    onSuccess: () => {
      message.success('Partner created')
      queryClient.invalidateQueries({ queryKey: ['partners'] })
    },
    onError: err => {
      const errData = err.response?.data?.data
      const genericErrorMessage = 'Create Partner failed'
      const errorMessage: string = errData?.name?.[0] ?? genericErrorMessage

      // Some API messages start with lowercase so we have to normalize it
      message.error(capitalizeFirstLetter(errorMessage))
    }
  })

  const createPartner = (partner: Partner, callbacks?: MutationOptions) => {
    createMutation.mutate(partner, callbacks)
  }

  return {
    createPartner,
    createPartnerIsLoading: createMutation.isLoading
  }
}

export default useCreatePartner
