import React from 'react'

export const NoContactsIcon = () => (
  <svg width="161" height="152" viewBox="0 0 161 152" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M68.297 151.228C105.74 151.228 136.094 145.556 136.094 138.56C136.094 131.564 105.74 125.892 68.297 125.892C30.8537 125.892 0.5 131.564 0.5 138.56C0.5 145.556 30.8537 151.228 68.297 151.228Z" fill="#262626"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M122.534 101.344L98.6091 71.899C97.4611 70.513 95.7831 69.674 94.0161 69.674H42.5761C40.8101 69.674 39.1321 70.513 37.9841 71.899L14.0601 101.344V116.727H122.535V101.344H122.534Z" fill="#262626"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M34.3301 31.67H102.263C103.324 31.67 104.341 32.0914 105.092 32.8416C105.842 33.5917 106.263 34.6091 106.263 35.67V129.014C106.263 130.075 105.842 131.092 105.092 131.842C104.341 132.593 103.324 133.014 102.263 133.014H34.3301C33.2692 133.014 32.2518 132.593 31.5017 131.842C30.7515 131.092 30.3301 130.075 30.3301 129.014V35.67C30.3301 34.6091 30.7515 33.5917 31.5017 32.8416C32.2518 32.0914 33.2692 31.67 34.3301 31.67V31.67Z" fill="#595959"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M43.1781 41.623H93.4151C93.9455 41.623 94.4542 41.8337 94.8293 42.2088C95.2044 42.5839 95.4151 43.0926 95.4151 43.623V68.58C95.4151 69.1104 95.2044 69.6191 94.8293 69.9942C94.4542 70.3693 93.9455 70.58 93.4151 70.58H43.1781C42.6476 70.58 42.1389 70.3693 41.7639 69.9942C41.3888 69.6191 41.1781 69.1104 41.1781 68.58V43.623C41.1781 43.0926 41.3888 42.5839 41.7639 42.2088C42.1389 41.8337 42.6476 41.623 43.1781 41.623ZM43.4401 81.437H93.1531C93.753 81.437 94.3283 81.6753 94.7526 82.0995C95.1768 82.5237 95.4151 83.0991 95.4151 83.699C95.4151 84.2989 95.1768 84.8743 94.7526 85.2985C94.3283 85.7227 93.753 85.961 93.1531 85.961H43.4401C42.8402 85.961 42.2648 85.7227 41.8406 85.2985C41.4164 84.8743 41.1781 84.2989 41.1781 83.699C41.1781 83.0991 41.4164 82.5237 41.8406 82.0995C42.2648 81.6753 42.8402 81.437 43.4401 81.437ZM43.4401 93.2H93.1531C93.7531 93.2 94.3286 93.4384 94.7529 93.8627C95.1772 94.287 95.4156 94.8624 95.4156 95.4625C95.4156 96.0626 95.1772 96.638 94.7529 97.0623C94.3286 97.4866 93.7531 97.725 93.1531 97.725H43.4401C42.84 97.725 42.2646 97.4866 41.8403 97.0623C41.416 96.638 41.1776 96.0626 41.1776 95.4625C41.1776 94.8624 41.416 94.287 41.8403 93.8627C42.2646 93.4384 42.84 93.2 43.4401 93.2ZM122.313 136.702C121.538 139.773 118.816 142.062 115.578 142.062H21.0151C17.7771 142.062 15.0551 139.772 14.2811 136.702C14.1335 136.117 14.0589 135.516 14.0591 134.912V101.345H40.3771C43.2841 101.345 45.6271 103.793 45.6271 106.765V106.805C45.6271 109.776 47.9971 112.175 50.9041 112.175H85.6891C88.5961 112.175 90.9661 109.754 90.9661 106.782V106.77C90.9661 103.798 93.3091 101.344 96.2161 101.344H122.534V134.913C122.534 135.53 122.457 136.129 122.313 136.702Z" fill="#434343"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M125.621 33.292L118.791 35.942C118.615 36.0102 118.424 36.0275 118.239 35.992C118.054 35.9564 117.883 35.8693 117.746 35.7408C117.608 35.6123 117.51 35.4476 117.462 35.2656C117.413 35.0836 117.418 34.8917 117.474 34.712L119.411 28.505C116.822 25.561 115.302 21.971 115.302 18.097C115.302 8.102 125.42 0 137.902 0C150.381 0 160.5 8.102 160.5 18.097C160.5 28.092 150.382 36.194 137.901 36.194C133.373 36.194 129.157 35.128 125.621 33.292Z" fill="#595959"/>
    <path d="M146.804 21.365C148.378 21.365 149.653 20.1047 149.653 18.55C149.653 16.9953 148.378 15.735 146.804 15.735C145.231 15.735 143.955 16.9953 143.955 18.55C143.955 20.1047 145.231 21.365 146.804 21.365Z" fill="#434343"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M131.848 21.013H126.15L129.048 16.087L131.848 21.013ZM135.409 16.087H140.394V21.013H135.409V16.087Z" fill="#434343"/>
  </svg>
)
