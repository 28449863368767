import type { ReactNode } from 'react'
import React from 'react'

import { navigate } from '@reach/router'

import { PartnerActivityContainer } from '../../../PartnerActivity/PartnerActivityContainer'
import { PartnerBookingsContainer } from '../../../PartnerBookings/PartnerBookingsContainer'
import { PartnerContactsContainer } from '../../../PartnerContacts/PartnerContactsContainer'
import { PartnerOverviewContainer } from '../../../PartnerOverview/PartnerOverviewContainer'
import { PartnerProfilesContainer } from '../../../PartnerProfiles/PartnerProfilesContainer'
import { PartnerRoles } from '../../../PartnerRoles/PartnerRolesContainer'
import { TabVisibilityWrapper } from 'redesign/providers/TabVisibilityWrapper'

import { TabPane, Tabs } from 'components/Tabs'

import { getActiveAnchor } from 'utils/helpers'

import type Partner from 'redesign/types/Partner'

import { tabsStyles, tabContainerStyles } from './PartnerDetailTabs.module.css'

type PartnerDetailTabsProps = {
  partnerData: Partner
}

export const PartnerDetailTabs = (props: PartnerDetailTabsProps) => {
  const { partnerData } = props
  const { id } = partnerData
  const onChangeHandler = (tab: ReactNode) => navigate(`#${tab}`)

  return (
    <TabVisibilityWrapper>
      <Tabs
        className={tabsStyles}
        type="card"
        defaultActiveKey={getActiveAnchor('overview')}
        onChange={onChangeHandler}
      >
        <TabPane className={tabContainerStyles} tab={'Overview'} key="overview">
          <PartnerOverviewContainer partnerId={id} />
        </TabPane>
        <TabPane className={tabContainerStyles} tab={'Contacts'} key="contacts">
          <PartnerContactsContainer partner={partnerData} />
        </TabPane>
        <TabPane className={tabContainerStyles} tab={'Activity'} key="activity">
          <PartnerActivityContainer partnerId={id} />
        </TabPane>
        <TabPane className={tabContainerStyles} tab={'Roles'} key="roles">
          <PartnerRoles partner={partnerData} />
        </TabPane>
        <TabPane className={tabContainerStyles} tab={'Profiles'} key="profiles">
          <PartnerProfilesContainer partnerId={id} />
        </TabPane>
        <TabPane
          className={tabContainerStyles}
          tab={'Current Bookings'}
          key="currentBookings"
        >
          <PartnerBookingsContainer partnerId={id} />
        </TabPane>
      </Tabs>
    </TabVisibilityWrapper>
  )
}
