import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import type { AxiosError, AxiosResponse } from 'axios'

import {
  listPartnerTeams,
} from 'services/partnerTeams'

import type { PartnerTeam } from 'redesign/types/PartnerTeam'

import type {
  IUsePartnerTeamReturn,
  UsePartnerTeamParams
} from './usePartnerTeam.types'

const usePartnerTeam = ({
  partner
}: UsePartnerTeamParams): IUsePartnerTeamReturn => {
  const { data: partnerTeamData, isLoading } = useQuery<
    unknown,
    AxiosError,
    AxiosResponse<PartnerTeam[]>
  >({
    queryKey: ['partners-teams', partner],
    queryFn: () =>
      listPartnerTeams({
        params: {
          partner: partner ?? 0,
          populate: ['partner', 'team']
        }
      })
  })
  const data = useMemo(() => partnerTeamData?.data || [], [partnerTeamData])
  const dataCount = useMemo(
    () => partnerTeamData?.data.length || 0,
    [partnerTeamData]
  )

  return {
    data,
    dataCount,
    isLoading,
  }
}

export default usePartnerTeam
