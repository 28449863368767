import React from 'react'

import { DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'

import { Button } from 'components/Button'
import { message } from 'components/Message'
import { Progress } from 'components/Progress'
import { Dragger } from 'components/Upload'
import type {
  UploadRequestOption,
  RcFile,
  UploadFile,
  DraggerProps
} from 'components/Upload'

import type { UseFileUploadReturn } from 'redesign/hooks/useFileUpload/useFileUpload.types'

import { allowedImageFormats } from 'redesign/utils/constants'

import type Partner from 'redesign/types/Partner'

import {
  bannerStyles,
  bannerGradientStyles,
  emptyBannerStyles,
  actionButtonsStyles,
  deleteStyles
} from './PartnerBanner.module.css'

type Props = {
  partner: Partner
  fileUpload: UseFileUploadReturn
}

export const PartnerBanner = ({ partner, fileUpload }: Props) => {
  const { uploadFile, fileList, uploadProgress, deleteFile } = fileUpload

  const onChange: DraggerProps['onChange'] = info => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  const beforeUpload = (file: RcFile) => {
    const extension = file.name.split('.').pop()
    if (extension && !allowedImageFormats.includes(extension)) {
      message.error(`${file.name} file extension is not allowed.`)
      return false
    }
    return true
  }

  const uploadBanner = async (options: UploadRequestOption) => {
    const { file, onProgress } = options
    await uploadFile({
      collectionId: partner.id,
      fileId: (file as RcFile).uid,
      attributeName: 'banner',
      files: file as RcFile,
      onProgress
    })
  }

  const deleteBanner = (file?: UploadFile) => {
    // @TODO(TS migration)
    // @ts-expect-error 'id' prop does not exist on any of existing **File types.
    // Where does it coming from? Is it a bug?
    const fileId = file.uid ?? file.id
    deleteFile(Number(fileId))
  }

  const bannerUrl = partner?.banner?.url

  const draggerProps: DraggerProps = {
    accept: allowedImageFormats.join(','),
    onChange,
    beforeUpload,
    directory: false,
    multiple: false,
    onRemove: deleteBanner,
    customRequest: options => uploadBanner(options),
    fileList: (fileList as unknown) as Array<UploadFile<File>>,
    showUploadList: false,
    style: {
      background: bannerUrl
        ? `url(${bannerUrl}) no-repeat center center`
        : `#262626`,
      border: bannerUrl ? 'none' : undefined
    },
    className: bannerStyles,
    height: 183
  }

  return (
    <>
      <Dragger {...draggerProps}>
        {!bannerUrl && (
          <div className={emptyBannerStyles}>
            <div>
              <PlusOutlined width={32} height={32} />
            </div>
            <div>Click or drag an image file here to upload a banner.</div>
          </div>
        )}
        {bannerUrl && (
          <div className={bannerGradientStyles}>
            <Button
              size="large"
              type="text"
              className={actionButtonsStyles}
              icon={<UploadOutlined />}
            />
            <Button
              size="large"
              type="text"
              className={actionButtonsStyles}
              icon={<DeleteOutlined className={deleteStyles} />}
              onClick={event => {
                event.stopPropagation()
                deleteBanner(partner.banner)
              }}
            />
          </div>
        )}
        {uploadProgress > 0 ? <Progress percent={uploadProgress} /> : null}
      </Dragger>
    </>
  )
}
