import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listPartnerTeams = async params => {
  try {
    const response = await api.get(`/partner-teams`, params)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const createPartnerTeam = async params => {
  try {
    const response = await api.post(`/partner-teams`, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
