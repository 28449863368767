import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const deleteFile = async fileId => {
  try {
    const response = await api.delete(`/upload/files/${fileId}`)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}

export const uploadFile = async (file, params) => {
  try {
    const response = await api.post('/upload', file, params)
    return response
  } catch (e) {
    handleThrowError(e)
  }
}
