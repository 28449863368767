import { handleThrowError } from '../utils/services'

import { request as api } from './strapi/api'

export const listPartners = async params => {
  try {
    const response = await api.get(`/partners`, params)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const readPartner = async (partnerId, params) => {
  try {
    const response = await api.get(`/partners/${partnerId}`, params)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const createPartner = async partner => {
  try {
    const response = await api.post(`/partners`, partner)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const updatePartner = async (partnerId, params) => {
  try {
    const response = await api.put(`/partners/${partnerId}`, params)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const countPartners = async params => {
  try {
    const response = await api.get(`/partners/count`, params)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}

export const getPartnerStatistics = async partnerId => {
  if (!partnerId) {
    return { data: [] }
  }
  try {
    const response = await api.get(`/partners/${partnerId}/statistics`)
    return response
  } catch (e) {
    return handleThrowError(e)
  }
}
