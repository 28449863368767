import React from 'react'

import { Activity } from './components/Activity'

import { Skeleton } from 'redesign/components/Skeleton'

import usePartnerStatistics from '../../hooks/usePartnerStatistics'

type Props = {
  partnerId: number
}

export const PartnerActivityContainer = ({ partnerId }: Props) => {
  const { data, isLoading } = usePartnerStatistics({ partnerId })

  if (isLoading) {
    return <Skeleton active />
  }

  return <Activity partnerData={data} />
}
