import React from 'react'

import { RootComponent } from 'redesign/app/RootComponent'

import SEO from '../components/SEO'

import { PartnersPage as NewPartnersPage } from 'redesign/modules/Partners'

const PartnersPage = () => (
  <>
    <SEO title="Partners" />
    <RootComponent menuSelectedKeys={['partners']}>
      <NewPartnersPage />
    </RootComponent>
  </>
)

export default PartnersPage
