import React from 'react'

import { SocialIcon } from 'components/SocialIcon'

import type Partner from 'redesign/types/Partner'

import {
  containerStyles,
  nameStyles,
  socialIconsStyles
} from './PartnerNameSite.module.css'

type Props = {
  partner: Partner
}

export const PartnerNameSite = ({ partner }: Props) => {
  const { name, website = '', linkedinUrl = '' } = partner
  return (
    <div className={containerStyles}>
      <div className={nameStyles}>{name}</div>
      <div className={socialIconsStyles}>
        <SocialIcon value={linkedinUrl} type="linkedin" />
        <SocialIcon value={website} type="other" />
      </div>
    </div>
  )
}
