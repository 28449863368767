import React from 'react'

import { Switch } from 'antd'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Partner from 'redesign/types/Partner'

import { switchStyles } from './PartnerToggle.module.css'

type Props = {
  partner: Partner
  updatePartner: (values: Partner, callbacks?: MutationOptions) => void
}

export const PartnerToggle = ({ partner, updatePartner }: Props) => {
  const { active } = partner

  const onChangeHandler = (value: boolean) => {
    updatePartner({ ...partner, active: value })
  }

  return <Switch
    checkedChildren={'Active'}
    unCheckedChildren={'Inactive'}
    checked={active}
    onChange={onChangeHandler}
    className={switchStyles}
  />
}
