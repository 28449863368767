import React from 'react'

import { NoContactsIcon } from 'components/Svg/NoContactsIcon'

import { wrapper, title } from './NoContacts.module.css'

export const NoContacts = () => (
  <div className={wrapper}>
    <div><NoContactsIcon /></div>
    <div className={title}>No Contacts Found</div>
    <div>Looks like there are no contacts available. Start adding new contacts by clicking on the &ldquo;Create&rdquo; button.</div>
  </div>
)
