import React from 'react'

import moment from 'moment'

import { Col } from 'components/Col'
import { Row } from 'components/Row'
import { Skill as SkillComponent } from 'components/Skill'
import { Tooltip } from 'components/Tooltip'

import { convertDaysToFormattedString } from 'utils/helpers'

import type PartnerStatistics from 'redesign/types/PartnerStatistics'

import { metricTitleStyles, metricRowStyles } from './Activity.module.css'

type Props = {
  partnerData: PartnerStatistics
}

const Activity = ({ partnerData }: Props) => {
  const {
    openRoles,
    openBookings,
    expiredReservations,
    successRate,
    bookingFrequency,
    mostRecentBooking,
    mostRequestedSkills,
    avgBookingTime
  } = partnerData

  const fillRatePercentage = Math.floor(successRate * 100)
  const roundedBookingFrequency = Math.floor(bookingFrequency)

  return (
    <>
      <Row className={metricRowStyles}>
        <Col xs={12} md={4}>
          <h3 className={metricTitleStyles}>Open Roles</h3>
          <p>{openRoles || '-'}</p>
        </Col>

        <Col xs={12} md={4}>
          <h3 className={metricTitleStyles}>Current Bookings</h3>
          <p>{openBookings || '-'}</p>
        </Col>

        <Col xs={12} md={4}>
          <h3 className={metricTitleStyles}>Expired Reservations</h3>
          <p>{expiredReservations || '-'}</p>
        </Col>

        <Col xs={12} md={4}>
          <h3 className={metricTitleStyles}>Fill Rate</h3>
          <p>{isNaN(fillRatePercentage) ? '-' : `${fillRatePercentage}%`}</p>
        </Col>

        <Col xs={12} md={4}>
          <h3 className={metricTitleStyles}>Booking Frequency</h3>
          <p>
            {isNaN(roundedBookingFrequency)
              ? '-'
              : `${roundedBookingFrequency} days`}
          </p>
        </Col>

        <Col xs={12} md={4}>
          <h3 className={metricTitleStyles}>Most Recent Booking</h3>
          <p>{mostRecentBooking ? moment(mostRecentBooking).fromNow() : '-'}</p>
        </Col>
      </Row>

      <div
        style={{
          margin: '20px 0'
        }}
      >
        <h2>Most Requested Skills</h2>
        {Array.isArray(mostRequestedSkills)
          ? mostRequestedSkills.map(({ title, count }) => (
            <Tooltip
              key={title + count}
              title={`Requested ${count} time${count > 1 ? 's' : ''}`}
            >
              <SkillComponent
                key={title + count}
                title={title}
                variant="default"
              />
            </Tooltip>
          ))
          : '-'}
      </div>

      <div
        style={{
          fontSize: '16px'
        }}
      >
        <p>
          <span style={{ fontWeight: 'bold' }}>Average Time To Fill: </span>
          {avgBookingTime
            ? convertDaysToFormattedString(Math.floor(avgBookingTime))
            : '-'}{' '}
        </p>
      </div>
    </>
  )
}

export default Activity
