import React from 'react'

import { Link } from 'gatsby'
import moment from 'moment'

import { ProfilesTable } from '../../components/ProfilesTable'

import { ProfileNameColumn } from 'components/ProfileNameColumn'
import { SocialIcon } from 'components/SocialIcon'
import type { ColumnsType, SortOrder } from 'components/Table'

import { RecentActivities } from 'redesign/components/RecentActivities/RecentActivities'
import { Skeleton } from 'redesign/components/Skeleton'

import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'

import type PartnerRole from 'redesign/types/PartnerRole'
import type Profile from 'redesign/types/Profile'
import type RoleCandidate from 'redesign/types/RoleCandidate'

type Props = {
  partnerId: number
}

export const PartnerBookingsContainer = ({ partnerId }: Props) => {
  const { data: profiles, isLoading } = usePartnerRoleCandidates({
    params: {
      'partnerRole.partner': partnerId,
      closedAt_null: true
    }
  })

  const columns: ColumnsType<RoleCandidate> = [
    {
      title: 'Name/Email',
      render: ({ profile }: { profile: Profile }) => (
        <ProfileNameColumn profile={profile} filteredValue={undefined} />
      )
    },
    {
      title: 'Role',
      dataIndex: 'partnerRole',
      key: 'partnerRole',
      render: (value: PartnerRole) => (
        <Link to={`/role?id=${value.id}`}>{value.name}</Link>
      )
    },
    {
      title: 'Team',
      dataIndex: 'partnerRole',
      key: 'team',
      render: (value: PartnerRole) => (value?.team?.name ?? '')
    },
    {
      title: 'Recent Activity',
      key: 'roleCandidate',
      render: (value: RoleCandidate) => (
        <RecentActivities roleCandidate={value} />
      )
    },
    {
      title: 'Social',
      key: 'social',
      width: 150,
      render: (value: RoleCandidate) => {
        const { githubAccount, linkedinAccount, stackoverflowAccount } =
          value.profile ?? {}
        return (
          <div>
            {githubAccount && (
              <SocialIcon value={githubAccount} type="github" />
            )}{' '}
            {linkedinAccount && (
              <SocialIcon value={linkedinAccount} type="linkedin" />
            )}{' '}
            {stackoverflowAccount && (
              <SocialIcon value={stackoverflowAccount} />
            )}
          </div>
        )
      }
    },
    {
      title: 'Creation Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: Date) => moment(value).format('YYYY-MM-DD HH:mm'),
      sorter: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      defaultSortOrder: 'descend' as SortOrder
    }
  ]

  if (isLoading) {
    return <Skeleton active />
  }

  return <ProfilesTable profiles={profiles} columns={columns} />
}
