import React from 'react'

import { PartnerBanner } from '../Banner/PartnerBanner'
import { PartnerInfo } from '../Info/PartnerInfo'
import { PartnerLogo } from '../Logo/PartnerLogo'
import { PartnerNameSite } from '../NameSite/PartnerNameSite'
import { PartnerToggle } from '../Toggle/PartnerToggle'

import type { UseFileUploadReturn } from 'redesign/hooks/useFileUpload/useFileUpload.types'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Partner from 'redesign/types/Partner'
import type Timezone from 'redesign/types/Timezone'

import { header } from './PartnerMainSection.module.css'
type Props = {
  partner: Partner
  fileUpload: UseFileUploadReturn
  timezones: Timezone[]
  updatePartner: (values: Partner, callbacks?: MutationOptions) => void
}

export const PartnerMainSection = ({ partner, fileUpload, timezones, updatePartner }: Props) => (
  <main>
    <PartnerBanner partner={partner} fileUpload={fileUpload} />
    <div className={header}>
      <PartnerLogo partner={partner} fileUpload={fileUpload} />
      <PartnerNameSite partner={partner} />
      <PartnerToggle partner={partner} updatePartner={updatePartner} />
    </div>
    <PartnerInfo partner={partner} timezones={timezones} />
  </main>
)
