import React, { useState } from 'react'

import { Filters } from './components/Filters'
import type { FilterParams } from './components/Filters/Filters.types'
import { PartnerForm } from './components/PartnerForm'
import { PartnersTable } from './components/PartnersTable/PartnersTable'

import { Button } from 'components/Button'

import useCreatePartner from '../../hooks/useCreatePartner/useCreatePartner'
import usePartners from '../../hooks/usePartners/usePartners'

export const PartnersContainer = () => {
  const [filters, setFilters] = useState<FilterParams>({
    active: 'true'
  })
  const params = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => Boolean(value))
  )
  const { data, isLoading } = usePartners({ ...params })

  const { createPartner, createPartnerIsLoading } = useCreatePartner()

  const [createMode, setCreateMode] = useState(false)

  const closeForm = () => setCreateMode(false)
  const openForm = () => setCreateMode(true)

  return (
    <div>
      {createMode
        ? (
          <PartnerForm
            closeForm={closeForm}
            createPartner={createPartner}
            isLoading={createPartnerIsLoading}
          />
        )
        : (
          <Button type="primary" onClick={openForm}>
          New Partner
          </Button>
        )}
      <Filters filters={filters} setFilters={setFilters} />
      <PartnersTable isLoading={isLoading} partners={data} />
    </div>
  )
}
