import React from 'react'

import type { RadioChangeEvent } from 'components/RadioGroup'
import { RadioGroup } from 'components/RadioGroup'

import type { Props } from './Filters.types'

import { containerStyles } from './Filters.module.css'

const activeStatusOptions = [
  {
    label: 'Active',
    value: 'true'
  },
  {
    label: 'Inactive',
    value: 'false'
  },
  {
    label: 'All',
    value: ''
  }
]

const Filters = ({ filters, setFilters }: Props) => {
  const onStatusChange = (e: RadioChangeEvent) => {
    const { value } = e.target
    setFilters({ active: value })
  }

  return (
    <div className={containerStyles}>
      Partner Status:{' '}
      <RadioGroup
        options={activeStatusOptions}
        optionType="button"
        buttonStyle="solid"
        value={filters.active}
        onChange={onStatusChange}
      />
    </div>
  )
}

export default Filters
