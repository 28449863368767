import React from 'react'

import { Divider } from 'components/Divider'
import { Modal } from 'components/Modal'

import type { MutationOptions } from 'redesign/types/Mutation'
import type { PartnerContact } from 'redesign/types/PartnerContact'
import type { PartnerTeam } from 'redesign/types/PartnerTeam'

import { ContactForm } from './ContactForm'

type Props = {
  visible: boolean
  contact?: PartnerContact
  partnerTeamsData: PartnerTeam[]
  onClickSave: (contact: PartnerContact) => void
  onClickCancel: () => void
  addPartnerTeam: (team: PartnerTeam, callbacks?: MutationOptions) => Promise<void>
}

export const ContactModal = ({
  visible,
  contact,
  partnerTeamsData,
  onClickSave,
  onClickCancel,
  addPartnerTeam
}: Props) => (
  <Modal centered width="520px" open={visible} footer={null}>
    <div>
      <h2>Create Contact</h2>
      <Divider />
      <ContactForm
        contact={contact}
        teams={partnerTeamsData}
        onClickSave={onClickSave}
        onClickCancel={onClickCancel}
        addPartnerTeam={addPartnerTeam}
      />
    </div>
  </Modal>
)
