import React, { useMemo, useState } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import dayjs from 'dayjs'

import { useForm } from '../../../../../../../hooks/useForm'
import { App } from 'redesign/components'

import { SkillsField } from 'redesign/containers/FormFields'
import { IndustriesField } from 'redesign/containers/FormFields/IndustriesField'

import { Button } from 'components/Button'
import { Col } from 'components/Col'
import { DatePicker } from 'components/DatePicker'
import { Form, FormItem } from 'components/Form'
import { Input } from 'components/Input'
import { RadioGroup } from 'components/RadioGroup'
import { Row } from 'components/Row'
import { TimePicker } from 'components/TimePicker'
import TimezoneRangeInput from 'components/TimezoneRangeInput'

import { getJobTimezone } from 'hooks/usePartnerRoleConfig'

import { arrayToSelectOptions } from 'utils/helpers'

import { linkedInUrlRegex, websiteRegex } from 'redesign/utils/constants'
import { formatNegativeValues } from 'redesign/utils/helpers'

import type { MutationOptions } from 'redesign/types/Mutation'
import type Partner from 'redesign/types/Partner'
import type Timezone from 'redesign/types/Timezone'

type OverviewFormProps = {
  values: Partner
  rolesTimezones: Timezone[]
  closeForm: () => void
  updatePartner: (values: Partner, callbacks?: MutationOptions) => void
  updatePartnerIsLoading: boolean
}

const OverviewForm = ({
  values,
  rolesTimezones,
  closeForm,
  updatePartner,
  updatePartnerIsLoading
}: OverviewFormProps) => {
  const { modal: { confirm } } = App.useApp()
  const [form] = useForm()

  const initialValues = useMemo(
    () => ({
      ...values,
      dateOfIncorporation: dayjs(
        values.dateOfIncorporation
          ? new Date(values.dateOfIncorporation)
          : new Date()
      ),
      industries: values.industries?.map(item => ({
        value: item.id,
        label: item.description
      })),
      skills: arrayToSelectOptions(values.skills),
      workingHoursFrom: values.workingHoursFrom
        ? dayjs(values.workingHoursFrom, 'HH:mm:ss')
        : dayjs('00:00:00', 'HH:mm:ss'),
      workingHoursTo: values.workingHoursTo
        ? dayjs(values.workingHoursTo, 'HH:mm:ss')
        : dayjs('00:00:00', 'HH:mm:ss')
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values]
  )

  const isPartnerActive = initialValues ? initialValues.active : true
  const [isPartnerStatusActive, setIsPartnerStatusActive] =
    useState(isPartnerActive)

  const originalIndustries = values.industries || []
  const originalSkills = values.skills || []

  const onFinish = () => {
    const values = form.getFieldsValue()

    const isIndustriesTouched = form.isFieldTouched('industries')

    for (const industry of originalIndustries) {
      if (!values.industries.includes(industry.id)) {
        values.industries.push(industry.id * -1)
      }
    }

    const skills = formatNegativeValues(values.skills, originalSkills)

    const formattedValues = {
      ...values,
      workingHoursFrom: dayjs(values.workingHoursFrom).format('HH:mm:ss.SSS'),
      workingHoursTo: dayjs(values.workingHoursTo).format('HH:mm:ss.SSS'),
      industries: !isIndustriesTouched
        // @TODO(TS migration)
        // @ts-expect-error Industries type does NOT contain 'value' prop 🤔
        ? values.industries.map(({ value }) => value)
        : values.industries,
      skills
    }

    updatePartner(formattedValues, { onSuccess: closeForm })
  }

  const onClickCancel = () => {
    confirm({
      title: 'Discard unsaved changes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DISCARD',
      cancelText: 'CANCEL',
      onOk: () => {
        form.resetFields()
        closeForm()
      }
    })
  }

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <FormItem
            name="name"
            label="Partner Name:"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name of the Partner" />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem
            name="linkedinUrl"
            label="LinkedIn URL:"
            rules={[
              {
                validator: (_, value, callback) => {
                  const errorMessage =
                    'Please enter a valid LinkedIn company page URL'
                  if (!value || linkedInUrlRegex.test(value)) {
                    callback()
                  } else {
                    callback(errorMessage)
                  }
                }
              }
            ]}
          >
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 10 }}
              placeholder="https://linkedin.com/company/"
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <FormItem
            name="website"
            label="Website:"
            rules={[
              {
                validator: (_, value, callback) => {
                  const errorMessage = 'Please enter a valid website'
                  if (!value || websiteRegex.test(value)) {
                    callback()
                  } else {
                    callback(errorMessage)
                  }
                }
              }
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 10 }} />
          </FormItem>
        </Col>
        <Col xs={24} lg={12}>
          <FormItem
            name="description"
            label="Description:"
            rules={[{ required: true }]}
          >
            <Input.TextArea
              autoSize={{ maxRows: 4 }}
              placeholder="Description of the company"
            />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem
            name="industries"
            label="Industries:"
            rules={[{ required: false }]}
          >
            <IndustriesField mode="multiple" />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem name="active" label="Active:" rules={[{ required: true }]}>
            <Switch
              checked={isPartnerStatusActive}
              onChange={checked => setIsPartnerStatusActive(checked)}
            />
          </FormItem>
        </Col>
      </Row>

      <div
        style={{
          marginBottom: '10px'
        }}
      >
        Standard Working Hours:
      </div>
      <Row gutter={[16, 16]}>
        <Col xs={12} lg={3}>
          <FormItem
            name="workingHoursFrom"
            label="From:"
            rules={[{ required: false }]}
          >
            <TimePicker
              format={'HH:mm'}
              onSelect={time => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  workingHoursFrom: time
                })
              }}
            />
          </FormItem>
        </Col>

        <Col xs={12} lg={9}>
          <FormItem
            name="workingHoursTo"
            label="To:"
            rules={[
              { required: false },
              {
                validator: (rule, value, callback) => {
                  const errorMessage =
                    'Working Hours To time must be after Working Hours from time'
                  const workingHoursFrom =
                    form.getFieldValue('workingHoursFrom')

                  if (value && value.isBefore(workingHoursFrom)) {
                    callback(errorMessage)
                  } else {
                    callback()
                  }
                }
              }
            ]}
          >
            <TimePicker
              format={'HH:mm'}
              onSelect={time => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  workingHoursTo: time
                })
              }}
            />
          </FormItem>
        </Col>
        <Col xs={24} lg={12}>
          <TimezoneRangeInput
            isRequired
            shouldShowSearch={false}
            timezonesList={rolesTimezones}
            label="Timezone"
            initialValue={{
              baseTimezone: values.baseTimezone,
              timezoneOffsetRange: getJobTimezone(values, rolesTimezones)
            }}
            onSelect={undefined}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <FormItem
            name="dateOfIncorporation"
            label="Partner Since:"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem
            name="cultureSummary"
            label="Culture Summary:"
            rules={[{ required: false }]}
          >
            <Input.TextArea
              autoSize={{ maxRows: 4 }}
              placeholder="Describe the company culture here..."
            />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem
            name="skills"
            label="Tech Stack:"
            rules={[{ required: false }]}
          >
            <SkillsField />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem
            name="numberOfInterviews"
            label="Partner Interview Process:"
            rules={[{ required: false }]}
          >
            <Input.TextArea
              autoSize={{ maxRows: 4 }}
              placeholder="Express your interview processes"
            />
          </FormItem>
        </Col>

        <Col xs={24} lg={12}>
          <FormItem
            name="requiresCodingTest"
            label="Requires Live Code Testing?"
            rules={[{ required: false }]}
          >
            <RadioGroup
              options={[
                { value: false, label: 'No' },
                { value: true, label: 'Yes' }
              ]}
            />
          </FormItem>
        </Col>
      </Row>

      <Row
        justify="end"
        style={{
          marginBottom: '20px'
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={updatePartnerIsLoading}
          style={{
            marginRight: '10px'
          }}
        >
          Confirm
        </Button>
        <Button onClick={onClickCancel}>Cancel</Button>
      </Row>
    </Form>
  )
}

export default OverviewForm
